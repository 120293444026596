import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appCursorEndOnFocus]'
})
export class CursorEndOnFocusDirective {
  constructor(private element: ElementRef) {}

  @HostListener('focus')
  onFocus(): void {
    const input = this.element.nativeElement as HTMLInputElement;
    setTimeout(() => {
      input.selectionStart = input.selectionEnd = input.value.length;
    }, 0);
  }
}
