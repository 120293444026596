import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { AccessTypeEnum } from 'src/app/models/constants';

@Injectable({
  providedIn: 'root'
})
export class EmptyRouteResolver implements Resolve<void> {
  userAuthSubscription: Subscription;
  constructor(private router: Router, private authService: AuthService) {
    this.userAuthSubscription = new Subscription();
  }
  resolve(): void {
    this.userAuthSubscription.add(
      this.authService.userAuth.subscribe((loggedUser) => {
        if (loggedUser) {
          if (loggedUser.accessType === AccessTypeEnum.transportador) {
            this.router.navigateByUrl('/admin-etc/ofertas-fretes-listar');
            this.userAuthSubscription.unsubscribe();
          } else if (loggedUser.accessType === AccessTypeEnum.backoffice) {
            this.router.navigateByUrl('/admin-frotanet/administrativo-usuario-listar');
            this.userAuthSubscription.unsubscribe();
          } else if (loggedUser.accessType === AccessTypeEnum.callcenteradmin) {
            this.router.navigateByUrl('/admin-callcenter/motorista-listar');
            this.userAuthSubscription.unsubscribe();
          }
        } else {
          this.router.navigateByUrl('/authentication/login');
        }
      })
    );
  }
}
