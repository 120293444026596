import {
  adminChildren,
  callcenterRootMenu,
  coletasChildren,
  ofertasAgregamentoChildren,
  ofertasFreteChildren
} from "src/app/core/route/admin-callcenter.routes";
import { Menu } from "./menu-items.model";

const adminCallCenterMenu: Menu[] = [
  {
    state: '',
    name: callcenterRootMenu[0],
    type: 'sub',
    children: [
      { ...adminChildren[0] },
      { ...adminChildren[1] },
    ],
  },
  {
    state: '',
    name: callcenterRootMenu[1],
    type: 'sub',
    children: [
      { ...ofertasFreteChildren[0] },
      { ...ofertasFreteChildren[1] },
      { ...ofertasFreteChildren[2] },
    ]
  },
  {
    state: '',
    name: callcenterRootMenu[2],
    type: 'sub',
    children: [
      { ...ofertasAgregamentoChildren[0] },
      { ...ofertasAgregamentoChildren[1] },
      { ...ofertasAgregamentoChildren[2] },
    ]
  },
  {
    state: '',
    name: callcenterRootMenu[3],
    type: 'sub',
    children: [
      { ...coletasChildren[0] },
      { ...coletasChildren[1] },
    ]
  }
];

export class MenuItemsCallCenter {
  getMenuItem(): Menu[] {
    return adminCallCenterMenu;
  }
}
