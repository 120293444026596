import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, mergeMap } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.getToken().pipe(
      mergeMap(token => {
        const headers = req.headers
          .set('Authorization', `Bearer ${token}`)
          .set('Content-Type', 'application/json');
        const authReq = req.clone({ headers });
        return next.handle(authReq);
      })
    );
  }
}
