import {
  adminChildren,
  antecipacaoCreditoChildren,
  backofficeRootMenu,
  liquidacaoAntecipacaoChildren,
  ofertasAgregamentoChildren,
  ofertasFreteChildren
} from 'src/app/core/route/admin-frotanet.route';
import { Menu } from './menu-items.model';

const adminFrotanetMenu = [
  {
    state: '',
    name: backofficeRootMenu[0],
    type: 'sub',
    children: [
      { ...adminChildren[0] },
      { ...adminChildren[1] },
      { ...adminChildren[2] },
      { ...adminChildren[3] },
    ]
  },
  {
    state: '',
    name: backofficeRootMenu[1],
    type: 'sub',
    children: [
      { ...ofertasFreteChildren[0] },
      { ...ofertasFreteChildren[1] },
      { ...ofertasFreteChildren[2] },
    ]
  },
  {
    state: '',
    name: backofficeRootMenu[2],
    type: 'sub',
    children: [
      { ...ofertasAgregamentoChildren[0] },
      { ...ofertasAgregamentoChildren[1] },
      { ...ofertasAgregamentoChildren[2] },
    ]
  },
  {
    state: '',
    name: backofficeRootMenu[3],
    type: 'sub',
    children: [
      { ...antecipacaoCreditoChildren[0] },
      // { ...antecipacaoCreditoChildren[1] },
      { ...antecipacaoCreditoChildren[2] },
      { ...liquidacaoAntecipacaoChildren[1] },
    ]
  },
  {
    state: '',
    name: backofficeRootMenu[4],
    type: 'sub',
    children: [
      { ...liquidacaoAntecipacaoChildren[0] },
      // { ...liquidacaoAntecipacaoChildren[1] },
      // { ...liquidacaoAntecipacaoChildren[2] },
    ]
  }
];

export class MenuItemsBackoffice {
  getMenuItem(): Menu[] {
    return adminFrotanetMenu;
  }
}
