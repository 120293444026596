import { ChildrenItems } from "src/app/shared/menu-items/menu-items.model";

export const backofficeRootMenu = [
  "CADASTROS",
  "OFERTAS DE FRETE",
  "OFERTAS DE AGREGAMENTO",
  "ANTECIPAÇÃO DE CRÉDITO",
  "LIQUIDAÇÃO DE ANTECIPAÇÕES",
];

export const adminChildren: ChildrenItems[] = [
  { state: 'administrativo-empresa-listar', name: 'Transportadoras', type: 'link' },
  { state: 'cadastros-embarcador-listar', name: 'Embarcadores', type: 'link' },
  { state: 'administrativo-usuario-listar', name: 'Usuários', type: 'link' },
  { state: 'motorista-listar', name: 'Motoristas', type: 'link' },
]

export const ofertasFreteChildren: ChildrenItems[] = [
  { state: 'ofertas-frete-ativas', name: 'Ofertas Ativas', type: 'link' },
  { state: 'ofertas-frete-com-match', name: 'Ofertas com Match', type: 'link' },
  // { state: 'ofertas-frete-historico', name: 'Histórico', type: 'link' },
]

export const ofertasAgregamentoChildren: ChildrenItems[] = [
  { state: 'ofertas-agregamento-ativas', name: 'Ofertas Ativas', type: 'link' },
  { state: 'tacs-agregados', name: 'TACs Agregados', type: 'link' },
  { state: 'ofertas-agregamento-historico', name: 'Histórico', type: 'link' },
]

export const antecipacaoCreditoChildren: ChildrenItems[] = [
  { state: 'propostas-emitidas', name: 'Solicitações', type: 'link' },
  { state: 'aprovacao-transferencia-credito', name: 'Aprovação de Transferência', type: 'link' },
  { state: 'antecipacoes-realizadas', name: 'Antecipações', type: 'link' },
]

export const liquidacaoAntecipacaoChildren: ChildrenItems[] = [
  { state: 'conciliacao-de-depositos', name: 'Conciliação de Depósitos', type: 'link' },
  { state: 'antecipacoes-a-receber', name: 'Acompanhamento', type: 'link' },
  { state: 'aprovacao-transferencia-liquidacao', name: 'Aprovações de Transferência', type: 'link' }
]
