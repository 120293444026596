import { Injectable } from '@angular/core';
import { Menu } from './menu-items.model';
import { MenuItemsEtc } from './etc-menu-items';
import { AccessType, AccessTypeEnum } from 'src/app/models/constants';
import { MenuItemsCallCenter } from './callcenter-menu-items';
import { MenuItemsBackoffice } from './backoffice-menu-items';

const adminEmbarcadorMenu = [
  {
    state: '',
    name: 'ADMINISTRATIVO',
    type: 'sub',
    children: [
      { state: 'administrativo-empresa-listar', name: 'Empresa', type: 'link' },
      { state: 'administrativo-usuario-listar', name: 'Usuários', type: 'link' }
    ]
  },
  {
    state: '',
    name: 'ANTECIPAÇÕES',
    type: 'sub',
    children: [
      { state: 'analise-antecipacoes', name: 'Analisar Solicitações', type: 'link' },
      { state: 'antecipacoes-pendentes', name: 'Pendentes', type: 'link' },
      { state: 'antecipacoes-encerradas', name: 'Encerradas', type: 'link' }
    ]
  },
  {
    state: '',
    name: 'FATURAS E DEMONSTRATIVOS',
    type: 'sub',
    children: [
      { state: 'faturas', name: 'Faturas', type: 'link' },
      { state: 'demonstrativos', name: 'Demonstrativos', type: 'link' },
    ]
  }
];

const adminBancoMenu = [
  {
    state: '',
    name: 'ADMINISTRATIVO',
    type: 'sub',
    children: [
      { state: 'administrativo-banco-edicao/novo', name: 'Bancos', type: 'link' },
      { state: 'administrativo-usuario-listar', name: 'Usuários', type: 'link' }
    ]
  },
  {
    state: '',
    name: 'OPERACIONAL',
    type: 'sub',
    children: [
      { state: 'analise-antecipacoes-aprovadas', name: 'Antecipações à Pagar', type: 'link' },
      { state: 'analise-antecipacoes-receber', name: 'Antecipações à Receber', type: 'link' },
    ]
  }
];

@Injectable()
export class MenuItems {
  private _itensMenuTransportador!: Menu[];
  public get itensMenuTransportador() { return this._itensMenuTransportador; }
  private _itensMenuBackoffice!: Menu[];
  public get itensMenuBackoffice() { return this._itensMenuBackoffice; }
  private _itensMenuCallCenter!: Menu[];
  public get itensMenuCallCenter() { return this._itensMenuCallCenter; }
  private _itensMenuEmbarcador!: Menu[];
  public get itensMenuEmbarcador() { return this._itensMenuEmbarcador; }
  private _itensMenuBanco!: Menu[];
  public get itensMenuBanco() { return this._itensMenuBanco; }

  constructor() {
    // Realiza um clone da referencia dos objetos de menus completos.
    this.resetMenus();
  }

  getMenuitem(): Menu[] {
    return [
      {
        state: '',
        name: 'Menu',
        type: 'sub',
        children: [
          { state: '#', name: 'SubMenu1', type: 'link' },
          { state: '#', name: 'SubMenu2', type: 'link' }
        ]
      }
    ];
  }

  getAdminMenuItems(accessType: AccessType): Menu[] {
    if (accessType === AccessTypeEnum.transportador) {
      return this.itensMenuTransportador;
    } else if (accessType === AccessTypeEnum.backoffice) {
      return this.itensMenuBackoffice;
    } else if (accessType === AccessTypeEnum.callcenteradmin) {
      return this._itensMenuCallCenter;
    } else {
      return [];
    }
  }

  resetMenus() {
    this._itensMenuBackoffice = new MenuItemsBackoffice().getMenuItem();
    this._itensMenuCallCenter = new MenuItemsCallCenter().getMenuItem();
    this._itensMenuTransportador = new MenuItemsEtc().getMenuItem();
    this._itensMenuEmbarcador = Object.assign([], adminEmbarcadorMenu);
    this._itensMenuBanco = Object.assign([], adminBancoMenu);
  }
}
