import { Component, OnInit, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/core/auth/auth.service';
import { UserModel } from 'src/app/models/UserModel';
import { Subscription } from 'rxjs';
import { AccessTypeEnum } from 'src/app/models/constants';

@Component({
  selector: 'app-admin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class AdminHeaderComponent implements OnInit, OnDestroy {
  loggedUser!: UserModel;
  loggedUserSubscription!: Subscription;
  usersSubscription!: Subscription;

  moduleName!: string;
  AccessTypeEnum = AccessTypeEnum;

  constructor(private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.authService.userAuth.subscribe(user => {
      if (user && user.nome) {
        this.loggedUser = user;
        if (this.loggedUser.accessType === AccessTypeEnum.transportador) {
          this.moduleName = 'admin-etc';
        } else if (this.loggedUser.accessType === AccessTypeEnum.backoffice) {
          this.moduleName = 'admin-frotanet';
        } else if (this.loggedUser.accessType === AccessTypeEnum.callcenteradmin) {
          this.moduleName = 'admin-callcenter';
        } else {
          this.moduleName = '';
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.loggedUserSubscription) {
      this.loggedUserSubscription.unsubscribe();
    }
    if (this.usersSubscription) {
      this.usersSubscription.unsubscribe();
    }
  }

  myProfile() {
    this.router.navigate([`/${this.moduleName}/administrativo-usuario-registrar`, this.loggedUser.email]);
  }

  logout() {
    this.authService.signOut(true);
    localStorage.removeItem('dadosUsuario');
  }

  changePassword() {
    this.router.navigate([`/${this.moduleName}/administrativo-usuario-alterar-senha`, this.loggedUser.email]);
  }
}
