import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';
import { PkgMaterialModule } from './pkg-material-module';

import { MenuItems } from './menu-items/menu-items';
import { States } from './consts/states';
import {
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective
} from './accordion';
import { ChangePasswordContentComponent } from './components/change-password-content/change-password-content.component';
import { CurrencyFormatIPipe, CurrencyFormatPipe, CurrencyFormatZPipe } from './directives/currency-format.pipe';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { TimestampPipe } from './directives/timestamp.pipe';
import { DisableControlDirective } from './directives/disable-control.directive';
import { CapitalizeFirstLetterPipe } from './directives/capitalize-first-letter.pipe';
import { CardComponent } from './components/card/card.component';
import { ButtonComponent } from './components/button/button.component';
import { TitleDividerComponent } from './components/title-divider/title-divider.component';
import { SimpleInputComponent } from './components/inputs/simple-input/simple-input.component';
import { CheckboxComponent } from './components/inputs/checkbox/checkbox.component';
import { RadioButtonComponent } from './components/inputs/radio-button/radio-button.component';
import { CpfMaskHidePipe, EmailMaskHidePipe, PhoneMaskHidePipe } from './directives/hide-masks.pipe';
import { CursorEndOnFocusDirective } from './directives/cursor-end-onfocus.directive';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMM YYYY',
  },
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    PkgMaterialModule
  ],
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ChangePasswordContentComponent,
    CurrencyFormatPipe,
    CurrencyFormatZPipe,
    CurrencyFormatIPipe,
    TimestampPipe,
    DisableControlDirective,
    CapitalizeFirstLetterPipe,
    CardComponent,
    ButtonComponent,
    TitleDividerComponent,
    SimpleInputComponent,
    CheckboxComponent,
    RadioButtonComponent,
    CpfMaskHidePipe,
    PhoneMaskHidePipe,
    EmailMaskHidePipe,
    CursorEndOnFocusDirective
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    PkgMaterialModule,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ChangePasswordContentComponent,
    CurrencyFormatPipe,
    CurrencyFormatZPipe,
    CurrencyFormatIPipe,
    TimestampPipe,
    DisableControlDirective,
    CapitalizeFirstLetterPipe,
    CardComponent,
    ButtonComponent,
    TitleDividerComponent,
    SimpleInputComponent,
    CheckboxComponent,
    CpfMaskHidePipe,
    PhoneMaskHidePipe,
    EmailMaskHidePipe,
    CursorEndOnFocusDirective
  ],
  entryComponents: [
    ChangePasswordContentComponent
  ],
  providers: [
    MenuItems,
    States,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class SharedModule { }
