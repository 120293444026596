<app-loading uid='header'></app-loading>
<div class='user-info'>
  <div class='name'>
    <span *ngIf="loggedUser">{{loggedUser.nome}} {{loggedUser.sobrenome}}</span>
    <span *ngIf="!loggedUser">NO USER LOGGED</span>
  </div>
  <div class='company'>
    <span
      *ngIf="loggedUser && loggedUser.accessType === AccessTypeEnum.transportador && loggedUser.empresa">{{loggedUser.empresa.nomeFantasia}}</span>
    <span
      *ngIf="loggedUser && loggedUser.accessType === AccessTypeEnum.callcenteradmin && loggedUser.empresa">{{loggedUser.empresa.name}}</span>
    <span
      *ngIf="loggedUser && loggedUser.accessType === AccessTypeEnum.backoffice && loggedUser.empresa">{{loggedUser.empresa.name}}</span>
    <span *ngIf="!loggedUser || !loggedUser.empresa">NO COMPANY</span>
  </div>
</div>
<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<div class='user-photo' [matMenuTriggerFor]="profile">
  <img *ngIf="loggedUser" [src]="loggedUser.photoUrl" alt="user" class="profile-pic">
  <img *ngIf="!loggedUser" alt="user" class="profile-pic"
    src="https://firebasestorage.googleapis.com/v0/b/frotadev-7f775.appspot.com/o/default-user.png?alt=media&token=e6cf61f1-e4a9-4ddb-bba1-dc15a4b8a554">
</div>
<mat-menu #profile="matMenu" class="mymegamenu">
  <div class="options">
    <button class="btn-menu" mat-menu-item (click)='myProfile()'>
      DADOS DO USUÁRIO
    </button>
    <button class="btn-menu" mat-menu-item (click)='changePassword()'>
      TROCAR SENHA
    </button>
    <button class="btn-menu" mat-menu-item (click)='logout()'>
      ENCERRAR SESSÃO
    </button>
  </div>
</mat-menu>
