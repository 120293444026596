import { DocumentData, QueryDocumentSnapshot, SnapshotOptions } from "@angular/fire/compat/firestore";
import { DadosBancarioModel } from "./dados-gerais-empresa.model";
import { EnderecoModel } from "./driver/motorista.model";
import { UtilHandler } from "../core/util.handler";

export class PropostaAntecipacaoModel {
  id: string;
  dadosTransportadora: DadosCesssionarioSacadoModel;
  dadosSacado: DadosCesssionarioSacadoModel;
  dadosCessionario: DadosCesssionarioSacadoModel;
  dadosTitulo: DadosTituloModel;
  dadosUsuario: DadosUsuarioModel;
  dadosBancario: DadosBancarioModel;
  listaCtes: DadosCteModel[];
  dataCriacao: Date | undefined;
  dataAlteracao: Date;
  situacao: 'ativa' | 'cancelada';
  documentos: DocumentosAntecipacaoEntity;

  constructor() {
    this.id = '';
    this.dadosTransportadora = new DadosCesssionarioSacadoModel();
    this.dadosSacado = new DadosCesssionarioSacadoModel();
    this.dadosCessionario = new DadosCesssionarioSacadoModel();
    this.dadosTitulo = new DadosTituloModel();
    this.dadosUsuario = new DadosUsuarioModel();
    this.dadosBancario = new DadosBancarioModel();
    this.listaCtes = [];
    this.dataAlteracao = new Date();
    this.situacao = 'cancelada';
    this.documentos = new DocumentosAntecipacaoEntity();
  }

  static firestoreConverter = {
    toFirestore: (entityData: PropostaAntecipacaoModel): DocumentData => {
      const returnData: any = {};
      returnData.id = entityData.id;
      returnData.dadosCessionario = DadosCesssionarioSacadoModel.firestoreConverter.toFirestore(entityData.dadosCessionario);
      returnData.dadosSacado = DadosCesssionarioSacadoModel.firestoreConverter.toFirestore(entityData.dadosSacado);
      returnData.dadosTransportadora = DadosCesssionarioSacadoModel.firestoreConverter.toFirestore(entityData.dadosTransportadora);
      returnData.dadosTitulo = DadosTituloModel.firestoreConverter.toFirestore(entityData.dadosTitulo);
      returnData.dadosUsuario = DadosUsuarioModel.firestoreConverter.toFirestore(entityData.dadosUsuario);
      returnData.dadosBancario = DadosBancarioModel.firestoreConverter.toFirestore(entityData.dadosBancario);
      returnData.listaCtes = entityData.listaCtes.map((cte) => DadosCteModel.firestoreConverter.toFirestore(cte));
      if (entityData.dataCriacao) {
        returnData.dataCriacao = entityData.dataCriacao;
      }
      returnData.dataAlteracao = entityData.dataAlteracao;
      returnData.situacao = entityData.situacao;
      returnData.documentos = DocumentosAntecipacaoEntity.firestoreConverter.toFirestore(entityData.documentos);
      return returnData;
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions) => {
      const data = snapshot.data(options);
      data["dataCriacao"] = UtilHandler.convertToDateType(data["dataCriacao"]);
      data["dataAlteracao"] = UtilHandler.convertToDateType(data["dataAlteracao"]);
      data["dadosTitulo"]["dataVencimento"] = UtilHandler.convertToDateType(data["dadosTitulo"]["dataVencimento"]);
      data["dadosTitulo"]["dataEmissao"] = UtilHandler.convertToDateType(data["dadosTitulo"]["dataEmissao"]);
      if (data["listaCtes"]) {
        data["listaCtes"].forEach((cte: any) => {
          cte["dataEmissao"] = UtilHandler.convertToDateType(cte["dataEmissao"]);
          cte["dataEntrega"] = UtilHandler.convertToDateType(cte["dataEntrega"]);
        });
      }
      return Object.assign(new PropostaAntecipacaoModel(), data);
    }
  }
}

export class DadosCesssionarioSacadoModel {
  id: string;
  cnpj: string;
  razaoSocial: string;
  inscricaoEstadual: string;
  telefone: string;
  endereco: EnderecoModel;
  externalId: string;
  imgLogoBase64: string;

  constructor() {
    this.id = '';
    this.cnpj = '';
    this.razaoSocial = '';
    this.inscricaoEstadual = '';
    this.telefone = '';
    this.endereco = new EnderecoModel();
    this.externalId = '';
    this.imgLogoBase64 = '';
  }

  static firestoreConverter = {
    toFirestore: (entityData: DadosCesssionarioSacadoModel): DocumentData => {
      const returnData: any = {};
      if (entityData.id) returnData.id = entityData.id;
      returnData.cnpj = entityData.cnpj;
      returnData.razaoSocial = entityData.razaoSocial;
      if (entityData.inscricaoEstadual) returnData.inscricaoEstadual = entityData.inscricaoEstadual;
      if (entityData.telefone) returnData.telefone = entityData.telefone;
      returnData.endereco = EnderecoModel.firestoreConverter.toFirestore(entityData.endereco);
      if (entityData.externalId) returnData.externalId = entityData.externalId;
      if (entityData.imgLogoBase64) returnData.imgLogoBase64 = entityData.imgLogoBase64;
      return returnData;
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions) => {
      return Object.assign(new DadosCesssionarioSacadoModel(), snapshot.data(options));
    }
  }
}

export class DadosTituloModel {
  valorBruto: number;
  valorDesconto: number;
  valorLiquido: number;
  motivoDesconto: string;
  dataVencimento: Date | null;
  dataEmissao: Date | null;
  valorTaxaDesconto: number;
  valorTaxaEmissaoContrato: number;
  valorAReceber: number;
  valorMultaMora: number;
  valorJurosMora: number;
  numeroFatura: string;
  numeroOperacao: string;
  codigoAutorizacao: string;
  valorBrutoPorExtenso: string;
  valorLiquidoPorExtenso: string;
  valorMultaMoraPorExtenso: string;
  valorJurosMoraPorExtenso: string;
  indiceCorrecao: string;
  pracaPagamento: string;
  policeId: string;

  constructor() {
    this.valorBruto = 0;
    this.valorDesconto = 0;
    this.valorLiquido = 0;
    this.motivoDesconto = '';
    this.dataVencimento = null;
    this.dataEmissao = null;
    this.valorTaxaDesconto = 0;
    this.valorTaxaEmissaoContrato = 0;
    this.valorAReceber = 0;
    this.valorMultaMora = 0;
    this.valorJurosMora = 0;
    this.numeroFatura = '';
    this.numeroOperacao = '';
    this.codigoAutorizacao = '';
    this.valorBrutoPorExtenso = '';
    this.valorLiquidoPorExtenso = '';
    this.valorMultaMoraPorExtenso = '';
    this.valorJurosMoraPorExtenso = '';
    this.indiceCorrecao = '';
    this.pracaPagamento = '';
    this.policeId = '';
  }

  static firestoreConverter = {
    toFirestore: (entityData: DadosTituloModel): DocumentData => {
      return {
        valorBruto: entityData.valorBruto,
        valorDesconto: entityData.valorDesconto,
        valorLiquido: entityData.valorLiquido,
        motivoDesconto: entityData.motivoDesconto,
        dataVencimento: entityData.dataVencimento,
        dataEmissao: entityData.dataEmissao,
        valorTaxaDesconto: entityData.valorTaxaDesconto,
        valorTaxaEmissaoContrato: entityData.valorTaxaEmissaoContrato,
        valorAReceber: entityData.valorAReceber,
        valorMultaMora: entityData.valorMultaMora,
        valorJurosMora: entityData.valorJurosMora,
        numeroFatura: entityData.numeroFatura,
        numeroOperacao: entityData.numeroOperacao,
        codigoAutorizacao: entityData.codigoAutorizacao,
        valorBrutoPorExtenso: entityData.valorBrutoPorExtenso,
        valorLiquidoPorExtenso: entityData.valorLiquidoPorExtenso,
        valorMultaMoraPorExtenso: entityData.valorMultaMoraPorExtenso,
        valorJurosMoraPorExtenso: entityData.valorJurosMoraPorExtenso,
        indiceCorrecao: entityData.indiceCorrecao,
        pracaPagamento: entityData.pracaPagamento,
        policeId: entityData.policeId,
      }
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions) => {
      const data = snapshot.data(options);
      data["dataVencimento"] = data["dataVencimento"].toDate();
      data["dataEmissao"] = data["dataEmissao"].toDate();
      return Object.assign(new DadosTituloModel(), data);
    }
  }
}

export class DadosCteModel {
  idCte: string;
  numeroCte: string;
  valorCte: number;
  dataEmissao: Date | null;
  dataEntrega: Date | null;
  notasFiscais: string;
  fretePeso: number;
  valorCarga: number;
  valorPedagio: number;
  valorFrete: number;
  mercadoria: string;
  dadosDestinatario: DadosCesssionarioSacadoModel;

  constructor() {
    this.idCte = '';
    this.numeroCte = '';
    this.valorCte = 0;
    this.dataEmissao = null;
    this.dataEntrega = null;
    this.notasFiscais = '';
    this.fretePeso = 0;
    this.valorCarga = 0;
    this.valorPedagio = 0;
    this.valorFrete = 0;
    this.mercadoria = '';
    this.dadosDestinatario = new DadosCesssionarioSacadoModel();
  }

  static firestoreConverter = {
    toFirestore: (entityData: DadosCteModel): DocumentData => {
      const returnData: any = {};
      returnData.idCte = entityData.idCte;
      returnData.numeroCte = entityData.numeroCte;
      returnData.valorCte = entityData.valorCte;
      returnData.dataEmissao = entityData.dataEmissao;
      returnData.dataEntrega = entityData.dataEntrega;
      returnData.notasFiscais = entityData.notasFiscais;
      returnData.fretePeso = entityData.fretePeso ?? 0;
      returnData.valorCarga = entityData.valorCarga;
      returnData.valorPedagio = entityData.valorPedagio;
      returnData.valorFrete = entityData.valorFrete;
      returnData.mercadoria = entityData.mercadoria;
      returnData.dadosDestinatario = DadosCesssionarioSacadoModel.firestoreConverter.toFirestore(entityData.dadosDestinatario);
      return returnData;
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions) => {
      const data = snapshot.data(options);
      data["dataEmissao"] = data["dataEmissao"].toDate();
      data["dataEntrega"] = data["dataEntrega"].toDate();
      return Object.assign(new DadosCteModel(), data);
    }
  }
}

export class DadosUsuarioModel {
  cpf: string;
  nome: string;
  email: string;
  myIp: string;

  constructor() {
    this.cpf = '';
    this.nome = '';
    this.email = '';
    this.myIp = '';
  }

  static firestoreConverter = {
    toFirestore: (dadosUsuario: DadosUsuarioModel): DocumentData => {
      return {
        cpf: dadosUsuario.cpf,
        nome: dadosUsuario.nome,
        email: dadosUsuario.email,
        myIp: dadosUsuario.myIp,
      }
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions) => {
      return Object.assign(new DadosUsuarioModel(), snapshot.data(options));
    }
  }
}

export class DocumentosAntecipacaoEntity {
  pathFatura: string;
  pathDuplicata: string;
  pathTermoCessao: string;
  pathTermoDeclaracao: string;
  pathExtratoConhecimentos: string;
  pathCertificadoCobertura: string;
  pathPropostaAntecipacao: string;
  pathPropostaAntecipacaoCredito: string;
  pathReciboTransferencia: string;

  constructor() {
    this.pathFatura = '';
    this.pathDuplicata = '';
    this.pathTermoCessao = '';
    this.pathTermoDeclaracao = '';
    this.pathExtratoConhecimentos = '';
    this.pathCertificadoCobertura = '';
    this.pathPropostaAntecipacao = '';
    this.pathPropostaAntecipacaoCredito = '';
    this.pathReciboTransferencia = '';
  }

  static firestoreConverter = {
    toFirestore: (proposta: DocumentosAntecipacaoEntity): DocumentData => {
      const returnData: any = {};
      returnData.pathFatura = proposta.pathFatura;
      returnData.pathDuplicata = proposta.pathDuplicata;
      returnData.pathTermoCessao = proposta.pathTermoCessao;
      returnData.pathTermoDeclaracao = proposta.pathTermoDeclaracao;
      returnData.pathExtratoConhecimentos = proposta.pathExtratoConhecimentos;
      returnData.pathCertificadoCobertura = proposta.pathCertificadoCobertura;
      returnData.pathPropostaAntecipacaoCredito = proposta.pathPropostaAntecipacaoCredito;
      returnData.pathReciboTransferencia = proposta.pathReciboTransferencia;
      return returnData;
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions) => {
      const data = snapshot.data(options);
      return Object.assign(new DocumentosAntecipacaoEntity(), data);
    }
  }
}
