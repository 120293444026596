import { ChildrenItems, SubChildren } from "src/app/shared/menu-items/menu-items.model";

export const etcRootMenu = [
  "ADMINISTRATIVO",
  "OFERTAS DE FRETE",
  "OFERTAS DE AGREGAMENTO",
  "COLETAS",
  "GESTÃO DE FRETES",
  "ANTECIPAÇÃO DE CRÉDITO",
];

export const adminChildren: ChildrenItems[] = [
  { state: 'administrativo-empresa-listar', name: 'Matriz e Filiais', type: 'link' },
  { state: 'administrativo-usuario-listar', name: 'Usuários', type: 'link' }
]

export const ofertasFreteChildren: ChildrenItems[] = [
  { state: 'ofertas-fretes-listar', name: 'Cadastro de Ofertas', type: 'link' },
  { state: 'tacs-selecionados-frete', name: 'TACs Selecionados', type: 'link' }
]

export const ofertasAgregamentoChildren: ChildrenItems[] = [
  { state: 'ofertas-agregamento-listar', name: 'Cadastro de Ofertas', type: 'link' },
  { state: 'tacs-selecionados-agregamento', name: 'TACs Agregados', type: 'link' }
]

export const coletasChildren: ChildrenItems[] = [
  { state: 'fretes-contratados-listar', name: 'Ordens de Coleta', type: 'link' },
  { state: 'historico-ordem-coleta-listar', name: 'Histórico de Coletas', type: 'link' }
]

export const gestaoFretesChildren: ChildrenItems[] = [
  { state: 'conhecimento-transporte-eletronico', name: 'CTEs', type: 'link' },
  { state: 'criar-viagem', name: 'Criar Viagem', type: 'link' },
  { state: 'fretes-em-transito', name: 'Fretes em Trânsito', type: 'link' },
  { state: 'confirmar-entregas', name: 'Confirmar Entregas', type: 'link' },
]

export const antecipacoesSubChildren: SubChildren[] = [
  { state: 'solicitar-antecipacoes', name: 'Solicitar', type: 'link' },
  { state: 'antecipacoes-a-receber', name: 'À Receber', type: 'link' },
  { state: 'antecipacoes-recebidas', name: 'Recebidas', type: 'link' },
]

export const creditoChildren: ChildrenItems[] = [
  { state: 'titulos-listar', name: 'Títulos', type: 'link' },
  { state: 'confirmar-performance', name: 'Confirmar Performance', type: 'link' },
  {
    state: '',
    name: 'Antecipações',
    type: 'subchild',
    subchildren: [
      { ...antecipacoesSubChildren[0] },
      { ...antecipacoesSubChildren[1] },
      { ...antecipacoesSubChildren[2] },
    ]
  }
]
